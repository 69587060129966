<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Données personnelles et cookies
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--60 pb--60 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner">
                <h2>Définition</h2>
                <p>
                  La présente charte (ci-après "la Charte") a pour objet
                  d’informer les utilisateurs (ci-après "l’Utilisateur") du
                  service "mes amis de confiance" (ci-après le " Site") des
                  moyens mis en œuvre pour :
                </p>
                <p>
                  Collecter les données à caractère personnel, conformément à la
                  Loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
                  fichiers et aux libertés, dans sa version actuelle (ci-après
                  "Loi Informatique et Libertés") ; consulter et conserver les
                  informations relatives à la navigation de l’Utilisateur sur le
                  Site susceptibles d’être enregistrées dans des fichiers «
                  cookies » (ci-après les "Cookies"). Le responsable de la
                  collecte des données à caractère personnel est La société
                  ShirkaLab (ci-après l’"Editeur"), société par actions
                  simplifiée, au capital social de 1600€ euros, inscrite au
                  registre du commerce et des sociétés de Paris sous le numéro
                  433 934 312, dont le siège social est situé 1-3 Rue d’Enghien
                  ,75010 PARIS (ci-après l' "Éditeur")
                </p>
                <p>
                  Le Charte peut être modifiée à tout moment par l'Éditeur, afin
                  notamment de se conformer à toutes évolutions réglementaires,
                  jurisprudentielles, éditoriales ou techniques. L'Utilisateur
                  doit se référer avant toute navigation à la dernière version
                  de la Charte.
                </p>
                <p>
                  La Charte fait partie intégrante des Conditions Générales
                  d'Utilisation du Site.
                </p>
                <p>
                  le cas échéant, la Charte s'applique concomitamment (i) aux
                  conditions particulières d'utilisation du Site et Applications
                  (ii) aux conditions générales de ventes, (iii) aux règlements
                  de jeux, (iv) à la charte de bonne conduite sur les services
                  interactifs du Site et des Applications.
                </p>
                <p>
                  Conformément à la Loi Informatique et Libertés, les
                  traitements de données à caractère personnel mis en œuvre par
                  l'Éditeur ont été déclarés auprès de la CNIL.
                </p>
                <h2>
                  I. PROTECTION DES DONNÉES
                </h2>
                <p>
                  Lors de l’utilisation du Site et des Applications par
                  l’Utilisateur, des données sont susceptibles d’être collectées
                  et traitées en vue de permettre l’accès aux services du Site
                  (ci-après les "Services") à des fins de gestion administrative
                  et commerciale.
                </p>
                <h3>1. QUELLES SONT LES DONNÉES COLLECTÉES ?</h3>
                <p>
                  Données de l'Utilisateur : désignent les données que
                  l'Utilisateur renseigne lors de la création d'un compte lors
                  de l'inscription au service ( nom, prénom, telephone, adresse,
                  email du soucripteurs et de ses contacts) et les données
                  relatives à la navigation : désignent les données que
                  l'Éditeur collecte lors de la navigation de l'Utilisateur sur
                  les Site et les Applications telles que notamment la date,
                  l'heure de la connexion et/ou navigation, le type de
                  navigateur, la langue du navigateur, son adresse IP.
                  L'ensemble des ces données seront collectivement visées
                  ci-après les "Données".
                </p>

                <h3>
                  2. POURQUOI L'ÉDITEUR COLLECTE-T-IL LES DONNÉES DE
                  L'UTILISATEUR?
                </h3>
                <p>
                  Les Données recueillies dans le cadre de l’utilisation du
                  service font l’objet d’un traitement dans le but de répondre
                  notamment aux finalités suivantes : -La gestion des accès à
                  certains Services ainsi que leur utilisation ;
                  <br />
                  -La constitution de fichiers de membres inscrits aux Services;
                  <br />
                  -L’élaboration de statistiques et de mesures de fréquentation
                  des Services;
                </p>
                <p>
                  Lors de la collecte des Données, l’Utilisateur sera informé si
                  certaines Données doivent être obligatoirement renseignées ou
                  si elles sont facultatives. Les Données identifiées par un
                  astérisque au sein du formulaire d’inscription sont
                  obligatoires. A défaut, l’accès aux Services et leur
                  utilisation par l’Utilisateur sera impossible.
                </p>
                <h3>3. DESTINATAIRES DES DONNÉES COLLECTÉES</h3>
                <p>
                  La base de Données constituée à l’occasion de l’inscription
                  aux Services est strictement confidentielle. L'Éditeur
                  s’engage à prendre toutes les précautions utiles, mesures
                  organisationnelles et techniques appropriées pour préserver la
                  sécurité, l’intégrité et la confidentialité des Données et
                  notamment, empêcher qu’elles soient déformées, endommagées ou
                  que des tiers non autorisés y aient accès.
                </p>
                <p>
                  Données transférées aux autorités et/ou organismes publics :

                  <br />
                  Conformément à la réglementation en vigueur, les Données
                  peuvent être transmises aux autorités compétentes sur requête
                  et notamment aux organismes publics, exclusivement pour
                  répondre aux obligations légales, les auxiliaires de justice,
                  les officiers ministériels et les organismes chargés
                  d’effectuer le recouvrement de créances.
                </p>

                <h3>4. ACCÈS AUX DONNÉES PERSONNELLES PAR L'UTILISATEUR</h3>
                <p>
                  Conformément à la loi n°78-17 du 6 janvier 1978 relative à
                  l’informatique, aux fichiers et aux libertés, toute personne
                  physique dispose du droit d’accès et, le cas échéant, la
                  rectification ou la suppression des Données la concernant.
                  L’exercice de ces droits s’effectue à tout moment :

                  <br />Par voie électronique : contact@shirkalab.io

                  <br />L'Éditeur rappelle que toute personne physique peut,
                  pour des motifs légitimes, s’opposer au traitement des Données
                  la concernant.
                </p>

                <h3>5. DURÉE DE CONSERVATION ET ARCHIVAGE DES DONNÉES</h3>
                <p>
                  Les Données des Utilisateurs ne seront pas conservées au-delà
                  de la durée strictement nécessaire aux finalités poursuivies
                  telles qu'énoncées au présentes et ce conformément à la Loi.
                  Les Données de l'Utilisateur sont effacées lorsque les durées
                  de conservations expirent. Néanmoins, les Données pourront
                  être archivées au-delà des durées prévues pour les besoins de
                  la recherche, la constatation, et de la poursuite des
                  infractions pénales dans le seul but de permettre, en tant que
                  besoins, la mise à disposition de ces Données à l’autorité
                  judiciaire. L'archivage implique que ces Données seront
                  anonymisées et ne seront plus consultables en ligne mais
                  seront extraites et conservées sur un support autonome et
                  sécurisé..
                </p>
                <h3>6. SÉCURITÉ</h3>
                <p>
                  L'Éditeur prend des mesures adéquates sur le plan de la
                  technique et de l'organisation pour interdire l'accès non
                  autorisé ou la divulgation des Données de l'Utilisateur.
                  Cependant, l'Éditeur ne peut garantir l'élimination de tout
                  risque d'utilisation abusive des Données.

                  <br />
                  L’Utilisateur est informé et accepte que les différentes
                  Données Publiques soient susceptibles d’être publiées de
                  manière centralisée lors de tout accès d’un Utilisateur à son
                  compte.
                </p>

                <h2>II. LES COOKIES</h2>
                <p>
                  Un cookie (ci-après le(s) "Cookie(s) ") est un fichier texte
                  susceptible d’être enregistré dans un terminal lors de la
                  consultation d’un Service avec un logiciel de navigation. Un
                  Cookie permet à son émetteur, pendant sa durée de validité, de
                  reconnaître le terminal concerné à chaque fois que ce terminal
                  accède à un contenu numérique comportant des Cookies du même
                  émetteur.
                </p>

                <p>
                  L’Utilisateur est informé que, lors de ses connexions sur le
                  Site ou les Applications et lors de l’utilisation des
                  Services, des Cookies sont installés sur son terminal de
                  réception (ordinateur, tablette, smartphone, etc.) pour le bon
                  fonctionnement des Services, ou sur son navigateur sous
                  réserve du choix exprimé par l’Utilisateur concernant les
                  cookies ; ce choix pouvant être modifié à tout moment.
                </p>

                <h3>1. QUELLES SONT LES FINALITÉS DES COOKIES?</h3>
                <p>
                  L'installation et le paramétrage du service sont à la charge
                  du bénéficiaire. Shirkalab ne pourra être tenu responsable
                  d'un mauvais paramétrage ou une mauvaise utilisation du
                  service.
                </p>

                <h4>a. Les Cookies de navigation sur le Site :</h4>
                <p>
                  Les Cookies de navigation permettent d’améliorer les
                  performances des Services afin de fournir à l’Utilisateur une
                  meilleure utilisation du Site et des Applications. Ces Cookies
                  ne requièrent pas l'information et l'accord préalable de
                  l'Utilisateur pour être déposés sur le terminal de
                  l'Utilisateur.
                  <br />
                  Plus précisément ces Cookies de navigation permettent
                  notamment D’adapter la présentation du Site et des
                  Applications aux préférences d’affichage du terminal (langue
                  utilisée, résolution d’affichage, système d’exploitation
                  utilisé, etc…) lors des visites des Utilisateurs sur le Site
                  et les Applications, selon les matériels et les logiciels de
                  visualisation ou de lecture que le terminal comporte .
                </p>

                <h4>b. Les Cookies de mesure d'audience :</h4>
                <p>
                  Les Cookies de mesure d'audience aident à établir des
                  statistiques et volumes de fréquentation et d’utilisation des
                  divers éléments composant le Site et les Applications
                  (rubriques et contenus visités, parcours), permettant à
                  l'Éditeur d’améliorer l’intérêt et l’ergonomie des Services.
                </p>

                <h3>2. COMMENT L'UTILISATEUR PEUT-IL GÉRER LES COOKIES?</h3>
                <p>
                  L'Utilisateur est tout d'abord informé que les partenaires de
                  l'Éditeur et tout autre tiers peuvent être amenés à déposer
                  des Cookies sur le Site. Seul l’émetteur d’un Cookie est
                  susceptible de lire ou de modifier des informations qui y sont
                  contenues et l'Éditeur n'a aucun accès ou contrôle sur les
                  Cookies que des tiers peuvent être amenés à utiliser.
                  L’émission et l’utilisation de Cookies par des tiers sont
                  soumises aux politiques de confidentialité de ces tiers en
                  plus des dispositions de la présente Charte. En conséquence
                  l'Utilisateur est invité à se rendre sur les sites Internet de
                  ces tiers pour plus d'informations sur les Cookies qu'ils
                  enregistrent et comment l'Utilisateur peut les gérer.
                </p>
                <p></p>
                <h4>L'accord sur les Cookies</h4>
                <p>
                  L’Utilisateur est présumé avoir donné son accord : en cliquant
                  sur l'icône "X" figurant sur le bandeau d'information visible
                  lors de sa première connexion au Site; en poursuivant sa
                  navigation, c’est-à-dire lorsque l’Utilisateur a cliqué sur un
                  élément du Site (image, lien, bouton « rechercher » etc.) ou
                  s’est rendu sur une autre page du Site. Si l'Utilisateur
                  accepte via son logiciel de navigation l'enregistrement de
                  Cookies dans son terminal, les Cookies intégrés dans les pages
                  et contenus qu'il a consultés pourront être stockés
                  temporairement dans un espace dédié de son terminal. Ils y
                  seront lisibles uniquement pas leur émetteur. L'accord donné
                  par l'Utilisateur n'est valable que pour une durée de treize
                  (13) mois à compter du premier dépôt dans l'équipement du
                  terminal de l'Utilisateur, faisant suite à l'expression du
                  consentement de ce dernier.
                </p>
                <h4>Le refus des Cookies</h4>
                <p>
                  Si l’Utilisateur refuse l'enregistrement de Cookies dans son
                  terminal ou son navigateur, ou s’il supprime ceux qui y sont
                  enregistrés, l’Utilisateur est informé que sa navigation et
                  son expérience sur le Site ou l'Application peuvent être
                  limitées. Cela pourrait également être le cas lorsque
                  l'Éditeur ou l’un de ses prestataires ne pourrait pas
                  reconnaître, à des fins de compatibilité technique, le type de
                  navigateur utilisé par le terminal, les paramètres de langue
                  et d'affichage ou le pays depuis lequel le terminal semble
                  connecté à Internet.

                  <br />Le cas échéant, l'Éditeur décline toute responsabilité
                  pour les conséquences liées au fonctionnement dégradé des
                  Services résultant (i) du refus de Cookies par l'Utilisateur
                  (ii) de l'impossibilité pour l'Éditeur d'enregistrer ou de
                  consulter les Cookies nécessaires à leur fonctionnement du
                  fait du choix de l'Utilisateur.
                </p>
                <h4>Configuration du logiciel de navigation</h4>
                <p>
                  L’Utilisateur peut configurer son logiciel de navigation de
                  manière à ce que des Cookies soient enregistrés dans le
                  terminal ou, au contraire, qu'ils soient rejetés, soit
                  systématiquement, soit selon leur émetteur. L’Utilisateur peut
                  également configurer son logiciel de navigation de manière à
                  ce que l'acceptation ou le refus des Cookies lui soient
                  proposés ponctuellement, avant qu'un Cookie soit susceptible
                  d'être enregistré dans son terminal.
                </p>
                <p>
                  Pour la gestion des Cookies et des choix de l’Utilisateur, la
                  configuration de chaque navigateur est différente. Elle est
                  décrite dans le menu d'aide du navigateur, qui permettra de
                  savoir de quelle manière modifier ses souhaits en matière de
                  Cookies :

                  <br />- Pour Internet Explorer™ :
                  http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                  <br />- Pour Safari™ :
                  https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
                  <br />- Pour Chrome™ :
                  http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                  <br />- Pour Firefox™ :
                  http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                  <br />- Pour Opera™ :
                  http://help.opera.com/Windows/10.20/fr/cookies.html <br />- À
                  tout moment, l’Utilisateur peut faire le choix d'exprimer et
                  de modifier ses souhaits en matière de Cookies.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "About",
          to: "",
          disabled: true
        }
      ]
    };
  }
};
</script>
